<template>
  <div>
    <!-- For demo: Revert true to indexStore.debugAds  -->
    <div
      v-if="true"
      class="w-full h-full bg-gray-200 text-white p-8 mx-auto"
    ></div>
    <div
      v-else-if="fullWidth"
      class="w-full mx-auto"
      :data-ad-id="id"
      :style="{
        minHeight: minHeight ? minHeight + 'px' : '',
      }"
    >
      <div class="flex justify-center" :id="id"></div>
    </div>
    <div
      v-else
      class="mx-auto"
      :data-ad-id="id"
      :id="id"
      :style="{
        minHeight: minHeight ? minHeight + 'px' : '',
      }"
    ></div>
  </div>
</template>
<script setup lang="ts">
const props = defineProps<{
  id: string
  fullWidth?: boolean
  minHeight?: number
}>()
const indexStore = useIndexStore()

useHead({
  script: [
    {
      innerHTML: `
  {
  let idToInject = {
        cmd: "injectNewAds",
        args: "${props.id}"
      };
      preConcept.queue.push(idToInject);
  }
  `,
      tagPosition: 'bodyClose',
    },
  ],
})

onUnmounted(() => {
  /*
This is a workaround to remove the ad label when navigating away from the page.
The external ad script runs after all DOM is loaded, so the label can be visible 
for some seconds on the page after page navigation, and this is not a good user experience.
Note: Our code for handling the ad label is in the app.vue file
*/
  if (import.meta.client) {
    const adsLabel = document.querySelector(`#ad-label-${props.id}`)
    if (adsLabel) {
      adsLabel.remove()
    }
  }
})
</script>
